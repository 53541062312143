<template>
  <div class="course-container">
    <div id="tools" class="tools">
      <div class="title">
        <img src="../../../../assets/1.png" height="20" width="20" style="margin-right: 5px" />
        设置目标
      </div>
      <div class="tools-button-wrap">
        <!--        <div style="width: 48px;padding-top: 2px" @click="addLine">-->
        <!--          <i class="iconfont icon-lianxian-copy"></i>-->
        <!--          <Icon type="md-git-compare" :size="18" />-->
        <!--        </div>-->
        <!--        <div style="width: 48px;" @click="addPoint">新增目标</div>-->
        <!--        <div style="width: 48px;" @click="deleteRelation(selectedLine)">删除</div>-->
        <div style="width: 48px;" @click="saveRelation">保存</div>
      </div>
      <div style="margin-top: 11px;margin-right: 50px">
        <Button type="primary" @click="returnManagement">
          <!--          <i class="iconfont icon-shouhuihuabu"></i>-->
          <!--          <Icon type="md-contract" :size="18" />-->
          返回
        </Button>
      </div>
    </div>
    <div class="canvas" id="topo-canvas" ref="canvas">
      <div class="zcc">
        <div></div>
      </div>
      <div ref="myDiagramDiv" id="myDiagramDiv"></div>
      <!--      <div class="canvas-tip" v-if="isDesc" :style="[{'top': top}, {'left': left}]" ref="canvasTip">{{description}}</div>-->
    </div>
    <Modal v-model="showAdd" title="新增目标" footer-hide>
      <Form ref="targetForm" :model="targetForm" :rules="targetRules">
        <FormItem prop="name">
          <Input type="text" v-model="targetForm.name" placeholder="请输入目标名称" />
        </FormItem>
        <FormItem prop="password">
          <Input v-model="targetForm.description" maxlength="200" show-word-limit type="textarea" placeholder="请输入目标描述" />
        </FormItem>
        <FormItem style="text-align: center">
          <Button type="primary" @click="onUpdate">提交</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import courseApi from '@api/course'
import { Topology } from 'topology-core'
import { registerNode } from 'topology-core/middles'
import {
  flowComment,
  flowCommentAnchors,
  flowData,
  flowDataAnchors,
  flowDataIconRect,
  flowDataTextRect,
  flowDb,
  flowDbIconRect,
  flowDbTextRect,
  flowDisplay,
  flowDisplayAnchors,
  flowDisplayIconRect,
  flowDisplayTextRect,
  flowDocument,
  flowDocumentAnchors,
  flowDocumentIconRect,
  flowDocumentTextRect,
  flowExternStorage,
  flowExternStorageAnchors,
  flowExternStorageIconRect,
  flowExternStorageTextRect,
  flowInternalStorage,
  flowInternalStorageIconRect,
  flowInternalStorageTextRect,
  flowManually,
  flowManuallyAnchors,
  flowManuallyIconRect,
  flowManuallyTextRect,
  flowParallel,
  flowParallelAnchors,
  flowQueue,
  flowQueueIconRect,
  flowQueueTextRect,
  flowSubprocess,
  flowSubprocessIconRect,
  flowSubprocessTextRect
} from 'topology-flow-diagram'
export default {
  name: 'Target',
  watch: {
    'canvas.data.pens': function(val, oldVal) {
      //监听节点和线的变动, 及时保存
      if (val.length > 0 && val[val.length - 1].type === 1) {
        this.selectedLine = val[val.length - 1]
        this.hasSelectLine = true
      }
    }
  },
  data() {
    return {
      canvas: {},
      canvasData: {
        nodes: [],
        lines: []
      },
      relationData: [],
      tools: [
        {
          group: '新增节点',
          children: [
            {
              name: '目标',
              icon: 'icon-rectangle',
              data: {
                text: '目标',
                rect: {
                  width: 120,
                  height: 40
                },
                name: 'rectangle'
              }
            }
          ]
        }
      ],
      lineSpace: 50,
      recWidth: 100,
      recHeight: 40,
      isDesc: false,
      description: '',
      top: '0px',
      left: '0px',
      showAdd: false,
      targetForm: {
        name: '',
        description: ''
      },
      targetRules: {
        name: [{ required: true, message: '目标名称不能为空', trigger: 'blur' }]
      },
      selectedNode: {},
      selectedLine: {},
      hasSelectNode: false,
      hasSelectLine: false,
      treeHeight: 30,
      lineSpaceY: 10,
      childRectY: 0,
      isOpposite: false,
      myDiagram: ''
    }
  },
  mounted() {
    this.initDraw()
    // let that = this
    // this.$refs.canvas.onmouseup = function(e) {
    //   that.onUpdate()
    // }
  },
  methods: {
    initDraw() {
      // courseApi.getTargetRelations().then(res => {
      //   this.relationData = res.res
      //   for (let i in this.relationData) {
      //     this.treeHeight +=
      //       (this.recHeight + this.lineSpaceY) * this.relationData.length
      //     this.initDrawData(
      //       this.relationData[i],
      //       this.relationData.length,
      //       i,
      //       -10 - this.recWidth,
      //       this.treeHeight,
      //       i === '0'
      //     )
      //     this.treeHeight += this.lineSpace
      //   }
      // })
      courseApi.getTargets().then(res => {
        let n = res.res.nodeDataArray.map(item => {
          item.color = item.pass ? '#EBF8DD' : '#FFFAEA'
          item.fontColor = item.pass ? '#5ABC50' : '#BB7D17'
          item.borderColor = item.pass ? '#BAE3B6' : '#EFDFA8'
          return item
        })
        let data = {
          nodeDataArray: n,
          linkDataArray: res.res.linkDataArray
        }
        this.initChart(data)
      })
    },
    initChart(val) {
      var go = this.go
      var $ = go.GraphObject.make // for conciseness in defining templates

      this.myDiagram = $(
        go.Diagram,
        'myDiagramDiv', // must name or refer to the DIV HTML element
        {
          LinkDrawn: showLinkLabel, // this DiagramEvent listener is defined below
          LinkRelinked: showLinkLabel,
          allowDelete: true,
          'undoManager.isEnabled': true // enable undo & redo
        }
      )

      function nodeStyle() {
        return [
          new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
          {
            // the Node.location is at the center of each node
            locationSpot: go.Spot.Center
          }
        ]
      }
      const that = this
      this.myDiagram.addDiagramListener('ObjectSingleClicked', function(e) {
        that.postName = e.subject.part.data.text
        that.getCourse(e.subject.part.data.key)
      })
      function makePort(name, align, spot, output, input) {
        var horizontal = align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom)
        return $(go.Shape, {
          fill: 'transparent', // changed to a color in the mouseEnter event handler
          strokeWidth: 0, // no stroke
          width: horizontal ? NaN : 8, // if not stretching horizontally, just 8 wide
          height: !horizontal ? NaN : 8, // if not stretching vertically, just 8 tall
          alignment: align, // align the port on the main Shape
          stretch: horizontal ? go.GraphObject.Horizontal : go.GraphObject.Vertical,
          portId: name, // declare this object to be a "port"
          fromSpot: spot, // declare where links may connect at this port
          fromLinkable: output, // declare whether the user may draw links from here
          toSpot: spot, // declare where links may connect at this port
          toLinkable: input, // declare whether the user may draw links to here
          cursor: 'pointer', // show a different cursor to indicate potential link point
          mouseEnter: function(e, port) {
            // the PORT argument will be this Shape
            if (!e.diagram.isReadOnly) port.fill = 'rgba(255,0,255,0.5)'
          },
          mouseLeave: function(e, port) {
            port.fill = 'transparent'
          }
        })
      }

      function textStyle() {
        return {
          font: 'bold 11pt Helvetica, Arial, sans-serif',
          stroke: '#333333'
        }
      }

      // define the Node templates for regular nodes
      this.myDiagram.nodeTemplateMap.add(
        '', // the default category
        $(
          go.Node,
          'Table',
          nodeStyle(),
          // the main object is a Panel that surrounds a TextBlock with a rectangular Shape
          $(
            go.Panel,
            'Auto',
            $(
              go.Shape,
              'Rectangle',
              { fill: 'white', stroke: '#555555', strokeWidth: 1 },
              new go.Binding('figure', 'figure'),
              new go.Binding('fill', 'color'),
              new go.Binding('stroke', 'borderColor')
            ),
            $(
              go.TextBlock,
              textStyle(),
              {
                margin: 8,
                maxSize: new go.Size(160, NaN),
                wrap: go.TextBlock.WrapFit,
                editable: true,
                stroke: '#333333'
              },
              new go.Binding('text').makeTwoWay(),
              new go.Binding('stroke', 'fontColor')
            )
          ),
          // four named ports, one on each side:
          makePort('T', go.Spot.Top, go.Spot.TopSide, false, true),
          makePort('L', go.Spot.Left, go.Spot.LeftSide, true, true),
          makePort('R', go.Spot.Right, go.Spot.RightSide, true, true),
          makePort('B', go.Spot.Bottom, go.Spot.BottomSide, true, false)
        )
      )
      // taken from ../extensions/Figures.js:
      go.Shape.defineFigureGenerator('File', function(shape, w, h) {
        var geo = new go.Geometry()
        var fig = new go.PathFigure(0, 0, true) // starting point
        geo.add(fig)
        fig.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0))
        fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h))
        fig.add(new go.PathSegment(go.PathSegment.Line, w, h))
        fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close())
        var fig2 = new go.PathFigure(0.75 * w, 0, false)
        geo.add(fig2)
        // The Fold
        fig2.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0.25 * h))
        fig2.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h))
        geo.spot1 = new go.Spot(0, 0.25)
        geo.spot2 = go.Spot.BottomRight
        return geo
      })
      // replace the default Link template in the linkTemplateMap
      this.myDiagram.linkTemplate = $(
        go.Link, // the whole link panel
        {
          routing: go.Link.AvoidsNodes,
          toShortLength: 4,
          relinkableFrom: true,
          relinkableTo: true,
          reshapable: true,
          resegmentable: true,
          // mouse-overs subtly highlight links:
          mouseEnter: function(e, link) {
            link.findObject('HIGHLIGHT').stroke = 'rgba(30,144,255,0.2)'
          },
          mouseLeave: function(e, link) {
            link.findObject('HIGHLIGHT').stroke = 'transparent'
          },
          selectionAdorned: false
        },
        new go.Binding('points').makeTwoWay(),
        $(
          go.Shape, // the highlight shape, normally transparent
          {
            isPanelMain: true,
            strokeWidth: 8,
            stroke: 'transparent',
            name: 'HIGHLIGHT'
          }
        ),
        $(
          go.Shape, // the link path shape
          { isPanelMain: true, stroke: 'gray', strokeWidth: 2 },
          new go.Binding('stroke', 'isSelected', function(sel) {
            return sel ? 'dodgerblue' : 'gray'
          }).ofObject()
        ),
        $(
          go.Shape, // the arrowhead
          { toArrow: 'standard', strokeWidth: 0, fill: 'gray' }
        ),
        $(
          go.Panel,
          'Auto', // the link label, normally not visible
          {
            visible: false,
            name: 'LABEL',
            segmentIndex: 2,
            segmentFraction: 0.5
          },
          new go.Binding('visible', 'visible').makeTwoWay(),
          $(
            go.Shape,
            'RoundedRectangle', // the label shape
            { fill: '#F8F8F8', strokeWidth: 0 }
          ),
          $(
            go.TextBlock,
            'Yes', // the label
            {
              textAlign: 'center',
              font: '10pt helvetica, arial, sans-serif',
              stroke: '#333333',
              editable: true
            },
            new go.Binding('text').makeTwoWay()
          )
        )
      )

      // Make link labels visible if coming out of a "conditional" node.
      // This listener is called by the "LinkDrawn" and "LinkRelinked" DiagramEvents.
      function showLinkLabel(e) {
        let links = that.myDiagram.findLinksByExample({
          from: e.subject.data.from,
          to: e.subject.data.to
        })
        if (links.count > 1) {
          that.myDiagram.model.removeLinkData(e.subject.data)
          return
        }
        var label = e.subject.findObject('LABEL')
        if (label !== null) label.visible = e.subject.fromNode.data.category === 'Conditional'
      }
      // temporary links used by LinkingTool and RelinkingTool are also orthogonal:
      // this.myDiagram.toolManager.linkingTool.temporaryLink.routing =
      //   go.Link.Orthogonal
      // this.myDiagram.toolManager.relinkingTool.temporaryLink.routing =
      //   go.Link.Orthogonal
      // // initialize the Palette that is on the left side of the page
      // $(
      //   go.Palette,
      //   'myPaletteDiv', // must name or refer to the DIV HTML element
      //   {
      //     nodeTemplateMap: this.myDiagram.nodeTemplateMap, // share the templates used by this.myDiagram
      //     model: newPaper go.GraphLinksModel([
      //       // specify the contents of the Palette
      //       { text: 'Step' }
      //     ])
      //   }
      // )
      let nodeData = $(go.GraphLinksModel)
      nodeData.nodeDataArray = val.nodeDataArray || []
      nodeData.linkDataArray = val.linkDataArray || []
      this.myDiagram.model = nodeData
    },
    saveRelation() {
      let a = this.myDiagram.model.linkDataArray
      a.forEach(item => {
        if (!Array.isArray(item.points)) {
          item.points = item.points.j
          let b = []
          item.points.forEach(i => {
            b.push(i.x)
            b.push(i.y)
          })
          item.points = b
        }
      })
      let form = {
        nodeDataArray: this.myDiagram.model.nodeDataArray,
        linkDataArray: this.myDiagram.model.linkDataArray
      }
      courseApi.saveTargets(form).then(res => {
        if (res.res) {
          this.$message.info('保存成功')
        } else {
          this.$message.error('保存失败')
        }
      })
    },
    initDrawData(data, length, index, parentRectX, parentY, isRoot, ...parentId) {
      let rect = {}
      let line = {}
      let node = {}
      if (parentRectX + this.recWidth + this.lineSpace > this.$refs.canvas.clientWidth) {
        parentRectX = parentRectX - this.recWidth - this.lineSpace
        if (this.isOpposite) {
          rect = this.initOppositeRect(index, parentRectX, this.childRectY)
          line = {
            name: 'line',
            to: {
              id: data.targetId,
              anchorIndex: 2,
              direction: 2,
              x: rect.x + this.recWidth,
              y: this.childRectY + rect.height / 2
            },
            from: {
              id: parentId[0],
              anchorIndex: 0,
              direction: 4,
              x: rect.x + this.recWidth + this.lineSpace,
              y: this.childRectY + rect.height / 2
            },
            toArrow: 'triangleSolid'
          }
        } else {
          rect = this.initRect(index, parentRectX, this.childRectY)
          line = {
            name: 'line',
            to: {
              id: data.targetId,
              anchorIndex: 2,
              direction: 2,
              x: rect.x + this.recWidth,
              y: this.childRectY + rect.height / 2
            },
            from: {
              id: parentId[0],
              anchorIndex: 2,
              direction: 2,
              x: rect.x + this.recWidth,
              y: isRoot ? parentY + rect.height / 2 : parentY + rect.height / 2 + this.lineSpaceY
            },
            toArrow: 'triangleSolid'
          }
        }
        node = {
          text: data.name,
          rect: rect,
          name: 'rectangle',
          id: data.targetId
        }
        this.canvas.addLine(line, false)
        this.isOpposite = true
      } else {
        if (this.isOpposite) {
          rect = this.initOppositeRect(index, parentRectX, this.childRectY)
          line = {
            name: 'line',
            to: {
              id: data.targetId,
              anchorIndex: 2,
              direction: 2,
              x: rect.x + this.recWidth,
              y: this.childRectY + rect.height / 2
            },
            from: {
              id: parentId[0],
              anchorIndex: 0,
              direction: 4,
              x: rect.x + this.recWidth + this.lineSpace,
              y: this.childRectY + rect.height / 2
            },
            toArrow: 'triangleSolid'
          }
          this.canvas.addLine(line, false)
        } else {
          rect = this.initRect(index, parentRectX, this.treeHeight)
          if (parentId.length > 0) {
            line = {
              name: 'line',
              to: {
                id: data.targetId,
                anchorIndex: 0,
                direction: 4,
                x: rect.x,
                y: rect.y + rect.height / 2
              },
              from: {
                id: parentId[0],
                anchorIndex: 2,
                direction: 2,
                x: rect.x - this.lineSpace,
                y: isRoot ? parentY + rect.height / 2 : parentY + rect.height / 2 + this.lineSpaceY
              },
              toArrow: 'triangleSolid'
            }
            this.canvas.addLine(line, false)
          }
        }
        node = {
          text: data.name,
          rect: rect,
          name: 'rectangle',
          id: data.targetId
        }
      }
      this.canvas.addNode(node, false)
      this.canvas.render()
      if (data.toTargets) {
        // 累加父元素和连线所占宽度
        if (this.isOpposite) {
          parentRectX -= this.recWidth + this.lineSpace
        } else {
          parentRectX += this.recWidth + this.lineSpace
        }
        for (let t in data.toTargets) {
          if (parseInt(t) > 0) {
            this.treeHeight += this.recHeight + this.lineSpaceY
          }
          if (data.toTargets.length > 1) {
            this.childRectY = this.treeHeight + (this.recHeight + this.lineSpaceY) * data.toTargets.length
            this.isOpposite = false
          }
          this.initDrawData(data.toTargets[t], data.toTargets.length, t, parentRectX, parentY, isRoot, data.targetId)
        }
      }
    },
    initRect(index, parentRectX, treeHeight) {
      let rectX = this.recWidth + this.lineSpace + parentRectX
      return {
        width: this.recWidth,
        height: this.recHeight,
        x: rectX,
        y: treeHeight
      }
    },
    initOppositeRect(index, parentRectX, treeHeight) {
      return {
        width: this.recWidth,
        height: this.recHeight,
        x: this.recWidth + this.lineSpace + parentRectX,
        y: treeHeight
      }
    },
    onClick(node) {
      this.showAdd = true
      node.data.rect.x = 50
      node.data.rect.y = 50
      this.canvas.addNode(node.data, true)
    },
    onUpdate() {
      let pens = this.canvas.data.pens
      for (let p in pens) {
        let pen = pens[p]
        if (pen.type === 0) {
          // 处理节点
          let node = {
            text: pen.text,
            rect: pen.rect,
            name: pen.name,
            id: pen.id
          }
        } else if (pen.type === 1) {
          // 处理连线
          let line = {
            text: pen.text,
            to: pen.to,
            from: pen.from
          }
        }
      }
    },
    showDesc(data) {
      for (let r in this.relationData) {
        if (this.relationData[r].targetId === data.id) {
          this.description = this.relationData[r].description
          this.top = data.rect.y + data.rect.height + 10 + 'px'
          this.left = data.rect.x + 'px'
          this.isDesc = true
        }
      }
    },
    hideDesc() {
      this.isDesc = false
    },
    addLine() {
      if (!this.hasSelectNode) {
        this.$message.error('请先选中一个目标!')
        return
      }
      this.hasSelectNode = false
      let line = {
        name: 'line',
        to: {
          x: this.selectedNode.rect.x + this.selectedNode.rect.width + 50,
          y: this.selectedNode.rect.y + this.selectedNode.rect.height / 2
        },
        from: {
          id: this.selectedNode.id,
          anchorIndex: 2,
          direction: 2,
          x: this.selectedNode.rect.x + this.selectedNode.rect.width,
          y: this.selectedNode.rect.y + this.selectedNode.rect.height / 2
        },
        toArrow: 'triangleSolid'
      }
      this.canvas.addLine(line, true)
    },
    updateRelation(data) {
      if (!data.to.id || !(this.hasSelectLine && this.selectedLine.id === data.id)) {
        return
      }
      courseApi.addTargetRelation(data.from.id, data.to.id).then(res => {
        if (res.res) {
          this.$message.info('自动保存')
        }
      })
    },
    deleteRelation(data) {
      if (!this.hasSelectLine) {
        this.$message.error('请先选中一根线')
        return
      }
      courseApi.deleteTargetRelation(data.from.id, data.to.id).then(res => {
        if (res.res) {
          this.$message.info('自动保存')
        }
        this.selectedLine = {}
        this.hasSelectLine = false
      })
    },
    returnManagement() {
      this.$router.push('/manage/study/target')
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables';
#myDiagramDiv {
  width: 100%;
  height: calc(100vh - 155px);
  background: url('../../../../assets/target_background.png');
  background-size: cover;
  box-shadow: 0px 2px 6px 0px rgba(230, 230, 230, 1);
  border-radius: 3px;
}
.course-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  background-color: @layout-body-background;
  position: relative;
  box-sizing: border-box;

  .tools {
    flex-shrink: 0;
    width: 100%;
    background-color: #fff;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;

    .title {
      font-family: Source Han Sans CN;
      font-size: 16px;
      color: #333333;
      margin-left: 30px;
      display: flex;
      align-items: center;
    }

    .tools-button-wrap {
      display: flex;
      div {
        background: #4579ea;
        box-shadow: 0px 1px 8px rgba(60, 100, 209, 0.5);
        border-radius: 4px;
        color: #fffefe;
        margin-top: 11px;
        height: 32px;
        line-height: 32px;
        margin-left: 10px;
      }
      div:hover {
        background: #3c64d1;
        cursor: pointer;
      }
    }

    .ivu-btn-primary {
      background-color: #4579ea;
    }

    .buttons {
      padding: 0.1rem 0;
      height: 300px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a {
        display: block;
        color: #314659;
        width: 40px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        text-decoration: none !important;
        cursor: pointer;

        .iconfont {
          font-size: 24px;
        }

        &:hover {
          color: #1890ff;
        }
      }
    }
  }

  .canvas {
    flex: 1;
    width: 100%;
    position: relative;
    overflow: auto;
    background: #fff;
    .zcc {
      width: 100%;
      /*height: 133px;*/
      height: 1px;
      position: absolute;
      top: 1px;
      padding: 0 1px 0 1px;
      z-index: 999;
      overflow: hidden;
      div {
        width: 100%;
        height: calc(100vh - 155px);
        background: #ffffff url('../../../../assets/target_background.png');
        background-position-x: -1px;
        background-size: cover;
      }
    }
    .canvas-tip {
      width: 240px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      position: absolute;
      border-radius: 3px;
      padding: 15px;
    }
  }
}
</style>
